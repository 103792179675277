import RectangleWrapper from "@/components/ui/RectangleWrapper";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { getCardsByCollection, getCollections } from "@/lib/actions";
import CollectionCardSheet from "@/src/components/collections/CollectionCard";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import React from "react";

export const Route = createFileRoute("/collections/$collectionId")({
	component: () => <CollectionId />,
});

const CollectionId = () => {
	const { data: collectionsData } = useQuery({
		queryKey: ["collections"],
		queryFn: async () => await getCollections(),
	});

	const { collectionId } = Route.useParams();

	const collection = collectionsData?.find(
		(collection) => collection.id === collectionId,
	);

	const { data: cardsData } = useQuery({
		queryKey: ["cards", collectionId],
		queryFn: async () => await getCardsByCollection(collectionId),
	});

	console.log({ cardsData });

	if (!collectionsData || !cardsData) {
		return <ScreenLoader />;
	}

	return (
		<main className="h-screen flex flex-col p-6 gap-[42px]">
			<div className="flex flex-col items-center pb-32">
				<h1 className="text-2xl text-center font-semibold transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					{collection?.title}
				</h1>
				<div className="mt-6 grid grid-cols-2 gap-2">
					{cardsData.map((card) => {
						return (
							<React.Fragment key={card.id}>
								<CollectionCardSheet card={card} />
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</main>
	);
};
