import ChevronRight from "@/components/icons/ChevronRight";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { getCollections } from "@/lib/actions";
import { cn } from "@/lib/utils";
import { useI18n } from "@/locales/client";
import { useQuery } from "@tanstack/react-query";

import { createFileRoute, Link } from "@tanstack/react-router";

const Collections = () => {
	const t = useI18n("collections");

	const { data: collectionsData } = useQuery({
		queryKey: ["collections"],
		queryFn: async () => await getCollections(),
	});

	if (!collectionsData) {
		return <ScreenLoader />;
	}

	console.log({ collectionsData });

	return (
		<main className="h-screen flex flex-col p-6 gap-[42px]">
			<div className="flex flex-col items-center gap-3 pb-32">
				<h1 className="text-2xl text-center font-semibold transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					{t("title")}
				</h1>
				{collectionsData?.map((collection) => {
					const isClaimable =
						collection.purchased_cards === collection.total_cards;

					return (
						<RectangleWrapper
							className={cn(
								"w-full p-2",
								isClaimable ? "border-yellow-1 shadow-yellow" : "",
							)}
						>
							<Link
								to={`/collections/${collection.id}`}
								className="flex items-center h-full"
							>
								<div className="mr-4">
									<img
										src={`${import.meta.env.VITE_PUBLIC_BASE_URL}/${
											collection.photo
										}`}
										alt=""
										className="rounded-md max-w-28 max-h-28"
									/>
								</div>
								<div className="flex flex-col h-full justify-between w-full">
									<div className="flex flex-col space-y-1">
										<div className="flex items-center justify-between">
											<p className="text-2xl font-semibold">
												{collection.title}
											</p>
											<ChevronRight />
										</div>
										<p className="text-xs text-gray-100">
											{t("total")}{" "}
											<span className="text-white">
												{collection.total_cards} {t("cards")}
											</span>
										</p>
									</div>
									<div className="w-20 h-px bg-gray-300"></div>
									<div className="flex flex-col space-y-1">
										<p className="text-gray-100 text-xs">{t("ready")}</p>
										<div className="flex items-center space-x-2">
											<div className="w-36 h-2 relative rounded-3xl border border-yellow-1">
												<div
													className="rounded-3xl relative bg-yellow-1 h-1.5 border border-yellow-1"
													style={{
														width: `${
															collection.total_cards
																? (collection.purchased_cards /
																		collection.total_cards) *
																  100
																: 0
														}%`,
													}}
												></div>
											</div>
											<p className="text-xs">
												{collection.total_cards
													? (collection.purchased_cards /
															collection.total_cards) *
													  100
													: 0}
												%
											</p>
										</div>
									</div>
								</div>
							</Link>
						</RectangleWrapper>
					);
				})}
			</div>
		</main>
	);
};

export const Route = createFileRoute("/collections/")({
	component: Collections,
});
