import {
	getArchiveQuests,
	getDailyRewards,
	getMeClient,
	getPaymentCoins,
	getQuests,
} from "@/lib/actions";
import { useCurrentLocale, useI18n } from "@/locales/client";
import * as Tabs from "@radix-ui/react-tabs";

import Task from "../../components/earn/Task";
import DailySheet from "../../components/earn/DailySheet";
import { useQuery } from "@tanstack/react-query";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { createFileRoute } from "@tanstack/react-router";
import StarsTask from "@/src/components/earn/StarsTask";
import { useState } from "react";
import useTelegram from "@/hooks/useTelegram";
import Skeleton from "@/components/ui/Skeleton";
import RectangleWrapper from "@/components/ui/RectangleWrapper";

function chunkByIndex<T>(arr: T[], index: number) {
	return [arr.slice(0, index), arr.slice(index, arr.length)];
}

const EarnPage = () => {
	const t = useI18n("earn");

	const locale = useCurrentLocale();

	const { data: daily_rewards } = useQuery({
		queryKey: ["daily-rewards"],
		queryFn: async () => await getDailyRewards(),
	});

	const { data: quests } = useQuery({
		queryKey: ["quests"],
		queryFn: async () => await getQuests(locale),
	});

	const { data: user } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	const { data: paymentCoins } = useQuery({
		queryKey: ["payment-coins"],
		queryFn: async () => await getPaymentCoins(),
	});

	const [type, setType] = useState<"weekly" | "archive">("weekly");

	const telegram = useTelegram();

	const { data: questsArchive, isLoading: archiveLoading } = useQuery({
		queryKey: ["quests-archive"],
		queryFn: async () => await getArchiveQuests(locale),
		enabled: type === "archive",
	});

	if (!t || !daily_rewards || !quests || !user || !paymentCoins) {
		return <ScreenLoader />;
	}

	const [questsFirstChunk, questsSecondChunk] = chunkByIndex(quests, 2);

	return (
		<main className="h-screen flex flex-col p-6 gap-[42px]">
			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl text-center font-semibold transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					{t("daily")}
				</h1>

				<DailySheet
					daily_rewards={daily_rewards}
					lastClaimDate={user.daily_last_dt}
				/>
			</div>

			<div className="flex flex-col items-center gap-4">
				<Tabs.Root
					value={type}
					onValueChange={(value) => {
						telegram?.HapticFeedback.selectionChanged();
						setType(value as "weekly" | "archive");
					}}
					className="w-full"
				>
					<Tabs.List className="flex items-center border-b border-gray-300 relative">
						<Tabs.Trigger
							value="weekly"
							className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
						>
							{t("weekly")}
						</Tabs.Trigger>

						<Tabs.Trigger
							value="archive"
							className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
						>
							{t("archive")}
						</Tabs.Trigger>

						<div
							style={{
								transform: {
									weekly: "translate(0%)",
									archive: "translate(100%)",
								}[type],
							}}
							className="w-1/2 h-0.5 bg-blue-300 transition-transform duration-300 ease-in-out absolute bottom-[-1.4px]"
						/>
					</Tabs.List>
				</Tabs.Root>

				<ul className="flex flex-col gap-4 pb-6 overflow-y-auto self-stretch max-h-[55vh] remove-scrollbar">
					{type === "weekly" ? (
						<>
							{questsFirstChunk.map((quest, index) => (
								<Task key={index} quest={quest} />
							))}
							<StarsTask
								minStars={paymentCoins[0].amount}
								minCoins={paymentCoins[0].coin}
							/>
							{questsSecondChunk.map((quest, index) => (
								<Task key={index} quest={quest} />
							))}
						</>
					) : (
						<>
							{archiveLoading
								? [1, 2, 3, 4, 5].map((i) => (
										<RectangleWrapper className="h-[72px] w-full">
											<Skeleton />
										</RectangleWrapper>
								  ))
								: questsArchive?.map((quest, index) => (
										<Task key={index} quest={quest} />
								  ))}
						</>
					)}
				</ul>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/earn/")({
	component: EarnPage,
});
