import React, { useEffect } from "react";
import MineIcon from "@/components/icons/MineIcon";
import UsersIcon from "@/components/icons/UsersIcon";
import CollectionsIcon from "@/components/icons/CollectionsIcon";
import CoinsIcon from "@/components/icons/CoinsIcon";
import AirdropIcon from "@/components/icons/AirdropIcon";
import ExchangeIcon from "@/components/icons/ExchangeIcon";
import { ReactElement } from "react";
import Nav from "@/components/ui/Nav";
import ArrowLeft from "../icons/ArrowLeft";
import CasinoMachine from "../icons/CasinoMachine";
import TrophyIcon from "../icons/TrophyIcon";
import ProfileIcon from "../icons/ProfileIcon";
import WalletIcon from "../icons/WalletIcon";
import { useSlotsOnboarding } from "../slots/onboarding/model";
import { useLocation } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

export interface NavRoute {
	label: string;
	href: string;
	icon: ReactElement;
	active: boolean;
	onSuccess?: VoidFunction;
}

const AppNavbar = () => {
	const { t } = useTranslation();

	const pathname = useLocation({
		select: (location) => location.pathname,
	});

	const defaultRoutes: NavRoute[] = [
		{
			label: t("nav.exchange"),
			href: "/",
			icon: <ExchangeIcon className="w-5" />,
			active: pathname === `/` || pathname.includes("league"),
		},
		{
			label: t("nav.mine"),
			href: "/mine/",
			icon: <MineIcon className="w-5" />,
			active: pathname.includes("mine"),
		},
		{
			label: t("nav.friends"),
			href: "/friends/",
			icon: <UsersIcon className="w-5" />,
			active: pathname === `/friends`,
		},
		{
			label: t("nav.earn"),
			href: "/earn/",
			icon: (
				<div className="relative">
					<div className="w-3 h-3 absolute -top-1 -right-1 bg-[#FF0000] rounded-full"></div>
					<CoinsIcon className="w-5" />
				</div>
			),
			active: pathname === `/earn`,
		},
		{
			label: t("nav.collections"),
			href: "/collections/",
			icon: <CollectionsIcon className="w-5" />,
			active: pathname.startsWith(`/collections`),
		},
		{
			label: t("nav.airdrop"),
			href: "/airdrop/",
			icon: <AirdropIcon className="w-5" />,
			active: pathname === `/airdrop`,
		},
	];

	const { onboardingEnabled, step, setOnboardingOnStep, skipOnboarding } =
		useSlotsOnboarding();

	const slotsRoutes: NavRoute[] = [
		{
			label: t("slots.return"),
			href: `/`,
			icon: <ArrowLeft />,
			active: step === 13 || step === 14,
		},
		{
			label: t("slots.lobby"),
			href: `/slots/lobby/`,
			icon: <CasinoMachine />,
			active: pathname === `/slots/lobby` && !onboardingEnabled,
			onSuccess: () => {
				onboardingEnabled && setOnboardingOnStep(1);
			},
		},
		{
			label: t("slots.top"),
			href: `/slots/top/`,
			icon: <TrophyIcon />,
			active: (pathname === `/slots/top` && !onboardingEnabled) || step === 3,
			onSuccess: () => {
				onboardingEnabled && setOnboardingOnStep(4);
			},
		},
		{
			label: t("slots.profile"),
			href: `/slots/profile/`,
			icon: <ProfileIcon />,
			active:
				(pathname === `/slots/profile` && !onboardingEnabled) || step === 6,
			onSuccess: () => {
				onboardingEnabled && setOnboardingOnStep(7);
			},
		},
		{
			label: t("slots.wallet"),
			href: `/slots/wallet/`,
			icon: <WalletIcon />,
			active:
				(pathname === `/slots/wallet` && !onboardingEnabled) || step === 9,
			onSuccess: () => {
				onboardingEnabled && setOnboardingOnStep(10);
			},
		},
	];

	const slotsPath = pathname.startsWith(`/slots`);
	const currentRoutes = slotsPath ? slotsRoutes : defaultRoutes;

	const hasActiveSlot =
		pathname.startsWith(`/slots/lobby`) &&
		pathname.split("/").filter(Boolean).length >= 3;
	const hideNavbar =
		(onboardingEnabled && ![3, 6, 9, 13, 14].includes(step)) ||
		(slotsPath && hasActiveSlot) ||
		pathname.includes("desktop");

	useEffect(() => {
		if (onboardingEnabled && !slotsPath) {
			skipOnboarding();
		}
	}, [pathname]);

	if (hideNavbar) {
		return null;
	}

	return <Nav routes={currentRoutes} />;
};

export default AppNavbar;
