import { useEffect } from "react";

import useTelegram from "@/hooks/useTelegram";
import { useLocation, useNavigate, useRouter } from "@tanstack/react-router";

const ScrollProvider = () => {
	const telegram = useTelegram();

	const pathname = useLocation({
		select: (location) => location.pathname,
	});
	const router = useRouter();
	const onBack = () => {
		router.history.back();
	};

	const navigate = useNavigate();

	const slotsPath = pathname.startsWith(`/slots`);

	useEffect(() => {
		if (
			pathname.includes("mine") ||
			pathname.includes("league") ||
			pathname.includes("friends") ||
			pathname.includes("airdrop") ||
			pathname.includes("collections")
		) {
			document.body.style.setProperty("overflow", "visible");
		} else {
			document.body.style.setProperty("overflow", "hidden");
		}

		if (pathname.split("/").length > 2 || pathname.includes("league")) {
			if (pathname.includes("collections")) {
				telegram?.BackButton.show();
				telegram?.BackButton.onClick(() => {
					navigate({ to: "/collections" });
				});
			} else {
				telegram?.BackButton.show();

				telegram?.BackButton.onClick(() => {
					if (slotsPath) {
						// note: sync it with path
						// e.g.: /{locale}/slots/lobby/{activeSlot}
						const hasActiveSlot =
							pathname.startsWith(`/slots/lobby`) &&
							pathname.split("/").filter(Boolean).length >= 3;

						if (hasActiveSlot) {
							navigate({ to: `/slots/lobby` });
							return;
						}

						const hasActiveWallet =
							pathname.startsWith("/slots/wallet/deposit") ||
							pathname.startsWith("/slots/wallet/withdraw");

						if (hasActiveWallet) {
							navigate({ to: `/slots/wallet` });
							return;
						}
					}
					navigate({ to: "/" });
				});
			}
		} else {
			telegram?.BackButton.hide();
		}

		if (pathname === `/`) {
			if (telegram?.headerColor !== "#01011A") {
				telegram?.setHeaderColor("#01011A");
			}
		} else {
			if (telegram?.headerColor !== "#000000") {
				telegram?.setHeaderColor("#000000");
			}
		}

		return () => {
			telegram?.BackButton.offClick(() => {
				onBack();
			});
		};
	}, [pathname]);

	return null;
};

export default ScrollProvider;
