import { IconProps } from "./types";

const LockIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="57"
			height="56"
			viewBox="0 0 57 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.5 3.5C21.7345 3.5 16.25 8.98451 16.25 15.75V22.75C12.384 22.75 9.25 25.884 9.25 29.75V45.5C9.25 49.366 12.384 52.5 16.25 52.5H40.75C44.616 52.5 47.75 49.366 47.75 45.5V29.75C47.75 25.884 44.616 22.75 40.75 22.75V15.75C40.75 8.98451 35.2655 3.5 28.5 3.5ZM37.25 22.75V15.75C37.25 10.9175 33.3325 7 28.5 7C23.6675 7 19.75 10.9175 19.75 15.75V22.75H37.25Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default LockIcon;
