import { IconProps } from "./types";

const CollectionsIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.75 22V2H16.25L20.75 7.25V22H4.75Z"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.75 7.5L14.292 10.8775L17.981 11.3005L15.245 13.8105L15.983 17.4495L12.75 15.6235L9.51704 17.4495L10.255 13.8105L7.51904 11.3005L11.208 10.8775L12.75 7.5Z"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default CollectionsIcon;
