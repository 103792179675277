import HourglassIcon from "@/components/icons/HourglassIcon";
import LoaderIcon from "@/components/icons/LoaderIcon";
import LockIcon from "@/components/icons/LockIcon";
import StarIcon from "@/components/icons/StarIcon";
import Button from "@/components/ui/Button";
import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import useTelegram from "@/hooks/useTelegram";
import {
	buyCardForOceanCoins,
	CollectionCard,
	paymentStars,
} from "@/lib/actions";
import { numberFormat, sleep } from "@/lib/utils";
import { useI18n } from "@/locales/client";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";

interface CollectionCardProps {
	card: CollectionCard;
}

// Если текущая дата до started at или после started at + show for,
// то она «неактивная» и ее нельзя купить за внутренние монеты
const CollectionCardSheet = ({ card }: CollectionCardProps) => {
	const isAvailableNow =
		new Date().getTime() >= new Date(card.started_at).getTime() &&
		new Date().getTime() < new Date(card.started_at).getTime() + card.show_for;

	const onlyBuyForStars = !isAvailableNow;

	const willBeAvailable =
		new Date().getTime() < new Date(card.started_at).getTime();
	// если еще не настала дата started_at
	const willBeAvailableIn =
		new Date(card.started_at).getTime() - new Date().getTime();
	// если настала дата, считаем оставшееся время
	const availableTime =
		new Date(card.started_at).getTime() + card.show_for - new Date().getTime();

	let timer = null;
	if (willBeAvailable) {
		timer = Date.now() + willBeAvailableIn;
	} else if (
		new Date().getTime() > new Date(card.started_at).getTime() &&
		new Date().getTime() < new Date(card.started_at).getTime() + card.show_for
	) {
		timer = Date.now() + availableTime;
	} else {
		timer = null;
	}

	const telegram = useTelegram();
	const queryClient = useQueryClient();
	const t = useI18n("collections");

	const [buyingLoading, setBuyingLoading] = useState(false);

	const buyCard = async () => {
		try {
			setBuyingLoading(true);
			// stars/ocean coins
			if (onlyBuyForStars) {
				const response = await paymentStars({
					type: "card",
					order: card.order_id,
					title: card.title,
					description: card.short_description,
				});
				telegram?.openTelegramLink(response.invoiceUrl);
			} else {
				const res = await buyCardForOceanCoins({
					cardId: card.id,
				});
				if (res.ok) {
					toast.success(res.message);
				} else {
					toast.error("Error");
				}
				console.log({ res });
			}
			// invalidate queries
			await sleep(3000);

			await queryClient.invalidateQueries({ queryKey: ["collections"] });
			await queryClient.invalidateQueries({
				queryKey: ["cards", card.collection_id],
			});
		} catch (err: any) {
			console.log({ err });
			toast.error("Error: ", err?.message);
		} finally {
			setBuyingLoading(false);
		}
	};

	return (
		<Sheet>
			<SheetTrigger asChild>
				<button className="relative">
					<RectangleWrapper className="p-2">
						{!card.owned ? (
							<div className="w-full h-full absolute top-0 left-0 backdrop-blur-sm rounded-xl border border-gray-300">
								<div className="flex flex-col space-y-2 items-center justify-center h-full">
									<LockIcon
										className={onlyBuyForStars ? "text-yellow-1" : ""}
									/>
									<div className="flex items-center space-x-1">
										<p
											className={
												onlyBuyForStars ? "text-yellow-1" : "text-white"
											}
										>
											{onlyBuyForStars
												? card.price_xrp
												: numberFormat(card.price_oc)}
										</p>
										{onlyBuyForStars ? (
											<StarIcon className="w-6 h-6 text-yellow-1" />
										) : (
											<Coin className="w-6 h-6" />
										)}
									</div>
								</div>
							</div>
						) : null}
						<div className="flex flex-col space-y-2">
							<p className="text-center text-ellipsis overflow-auto whitespace-nowrap max-w-full">
								{card.title}
							</p>
							<img
								src={`${import.meta.env.VITE_PUBLIC_BASE_URL}/${card.photo}`}
								alt=""
								className="rounded-md w-full h-20 object-cover"
							/>
							<div className="flex items-center justify-between">
								<div>
									<img
										src={`${import.meta.env.VITE_PUBLIC_BASE_URL}/${
											card.rarity_icon
										}`}
										alt=""
										className="w-6 h-6 object-contain"
									/>
								</div>
								{card.options?.map((option) => (
									<div>
										<img
											src={option.icon}
											alt=""
											className="w-6 h-6 object-contain"
										/>
									</div>
								))}
							</div>
						</div>
					</RectangleWrapper>
				</button>
			</SheetTrigger>

			<SheetContent
				side="bottom"
				className="flex flex-col items-center p-6 pb-12 gap-10"
			>
				<div className="max-w-full space-y-4">
					<img
						src={`${import.meta.env.VITE_PUBLIC_BASE_URL}/${card.photo}`}
						alt=""
						className="rounded-md w-full h-32 object-cover"
					/>
					<p className="text-center text-ellipsis overflow-auto whitespace-nowrap w-full text-3xl font-semibold">
						{card.title}
					</p>
					<div className="flex items-center justify-between flex-wrap">
						{card.options?.map((option) => (
							<div className="flex items-center">
								<img
									src={option.icon}
									alt=""
									className="w-6 h-6 object-contain"
								/>
								<p className="text-gray-100">{option.title}</p>
							</div>
						))}
					</div>
					<p className="text-xs">{card.full_description}</p>
					<div className="flex items-center justify-center w-full">
						<Coin className="w-6 h-6" />
						<p className="ml-2">+{card.coins_per_hour} </p>
						<span className="text-gray-100 ml-2">{t("perHour")}</span>
					</div>
					<div className="my-3 bg-blue-300 h-px w-20 mx-auto"></div>
					{!card.owned ? (
						<>
							{timer ? (
								<div className="flex flex-col items-center justify-center space-y-1">
									<p className="text-xs">
										{willBeAvailable ? t("willBe") : t("available")}
									</p>
									<div className="flex items-center space-x-1">
										<HourglassIcon />
										<p className="font-semibold text-xl">
											<Countdown date={timer} daysInHours />
										</p>
									</div>
								</div>
							) : null}
							<Button
								onClick={buyCard}
								className="w-full flex items-center"
								disabled={buyingLoading}
							>
								{buyingLoading ? (
									<LoaderIcon className="w-4 h-4 animate-spin mr-2" />
								) : null}
								<p className="mr-1">{t("buy")}</p>
								<div className="flex items-center space-x-1">
									<p
										className={onlyBuyForStars ? "text-yellow-1" : "text-white"}
									>
										{onlyBuyForStars
											? card.price_xrp
											: numberFormat(card.price_oc)}
									</p>
									{onlyBuyForStars ? (
										<StarIcon className="w-6 h-6 text-yellow-1" />
									) : (
										<Coin className="w-6 h-6" />
									)}
								</div>
							</Button>
						</>
					) : null}
				</div>
			</SheetContent>
		</Sheet>
	);
};

export default CollectionCardSheet;
