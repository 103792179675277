import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "@/styles/globals.css";
// i18n
import "../locales/client";

import telegramAnalytics from "@telegram-apps/analytics";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

import * as Sentry from "@sentry/react";
import Snowfall from "react-snowfall";

Sentry.init({
	dsn: "https://3947f6594fcb6fd3798a846fafd9ee2c@o4507899194638336.ingest.de.sentry.io/4508177848598608",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

telegramAnalytics.init({
	token:
		"eyJhcHBfbmFtZSI6Im9jZWFuX2tvbWJhdF9ib3QiLCJhcHBfdXJsIjoiaHR0cHMvL3QubWUvb2NlYW5fa29tYmF0X2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL2FwcC5vY2Vhbi1rb21iYXQuY29tLyJ9!letH5Y5UkeVI8mIDlZvpkIpLUaIIiidNm6CzKsGuyLs=",
	appName: "OceanKombat",
});

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<StrictMode>
			<RouterProvider router={router} />
			<Snowfall snowflakeCount={25} speed={[0.5, 2.0]} />
		</StrictMode>,
	);
}
