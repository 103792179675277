export default {
  nav: {
    exchange: 'Exchange',
    mine: 'Mine',
    friends: 'Friends',
    earn: 'Earn',
    airdrop: 'Airdrop',
    collections: 'Collections',
  },
  exchange: {
    tap: 'Tap',
    hour: 'Hour',
    dailyBoosters: 'Free Daily Boosters',
    fullEnergy: 'Full energy',
    available: 'available',
    go: 'Go',
    upgrade: 'Upgrade',
    turbo: 'Turbo',
    comingSoon: 'Coming soon',
    boosters: 'Boosters',
    multitap: 'Multitap',
    energyLimit: 'Energy limit',
    lvl: 'lvl',
    coinsEarned: 'Coins earned',
    claimCoins: 'Claim coins',
    lackOf: 'Lack of energy',
    error: 'Error',
    refilled: 'Energy refilled',
    success: 'Success',
    gameRoom: 'Game room',
    passiveIncome:
      'Passive income is active only for 4 hours while you are offline',
  },
  league: {
    from: 'from',
    top: 'Top-10 players in this league',
  },
  mine: {
    spinTo: 'Spin to win the daily combo',
    spin: 'Spin',
    strategy: 'Strategy',
    markets: 'Markets',
    marketing: 'Marketing',
    specials: 'Specials',
    perHour: 'per hour',
    buy: 'Buy',
    bannerTest: 'Test your',
    bannerFortune: 'fortune',
    bannerCasino: 'in Casino!',
    playNow: 'Play Now!',
  },
  friends: {
    inviteFriendsAnd: 'Invite friends and get bonuses together',
    inviteFriends: 'Invite friends',
    directRef: 'Direct Referral',
    refRef: "Referral's Referral",
    eachNext: 'Each Next Referral',
    stars: 'Earn Telegram Stars',
    starsRef: 'Get 30% of Telegram Stars spent by your referrals',
  },
  earn: {
    daily: 'Daily tasks',
    day: 'Day',
    claim: 'Claim',
    weekly: 'Weekly tasks',
    join: 'Join',
    archive: 'Archive',
  },
  airdrop: {
    getReady: 'Get ready, Airdrop is coming soon!',
    withExchanges: 'Negotiations with exchanges',
    withMarketMakers: 'Negotiations with market makers',
    key: 'Key partnerships on the way',
    taskList: 'Airdrop task list',
  },
  wheel: {
    spinTo: 'Spin to win the daily combo',
    spin: 'Spin',
    bonus: 'Bonus',
  },
  slots: {
    return: 'Return',
    lobby: 'Lobby',
    top: 'Top',
    profile: 'Profile',
    wallet: 'Wallet',
    onboarding1: `This is your balance within the casino, you can transfer coins from your game balance to the casino to play`,
    onboarding2: `This button will help you in topping up your casino balance using in-game coins`,
    onboarding3: `Click the “Top” button to go to the leaderboard`,
    onboarding4: `You can see the top for the last 24 hours`,
    onboarding5: `You can see the top for the last week`,
    onboarding6: `Click the “Profile” button to go to the your profile`,
    onboarding7: `This is your total statistic in our casino`,
    onboarding8: `This is your games statistic wins and loses`,
    onboarding9: `Click the “Wallet” button to go to the your casino wallet`,
    onboarding10: `This is your balance within the casino, you can transfer coins from your game balance to the casino to play`,
    onboarding11: `You can top up your casino balance or withdraw it`,
    onboarding12: `This is your transactions history - top up’s and withdraws`,
    onboarding13: `Click the “Return” button to back to the our game`,
    onboarding14: `Good luck, now you can play`,
    onboarding15: 'Thank you if you don’t skip tutorial',
    next: 'Next',
    skip: 'Skip tutorial',
    topUp: 'Top up',
    gamesHistory: 'Games history',
    totalSpins: 'Total spins',
    totalWon: 'Total won',
    yourProfile: 'Your profile',
    noData: 'No data',
    day: '24 hours',
    week: 'Week',
    casinoWallet: 'Casino wallet',
    balance: 'Balance',
    withdraw: 'Withdraw',
    transactionHistory: 'Transactions history',
    inGameBalance: 'Your in-game balance',
    inGame: 'In-game balance',
    casinoBalance: 'Casino balance',
    receive: 'You receive',
    give: 'You give',
    yourCasinoBalance: 'Your casino balance',
    toppedUp: 'Balance funded!',
    withdrawed: 'Successful withdraw!',
    withdrawError: 'Error on withdraw',
    depositError: 'Error on deposit',
  },
  stars: {
    buy: 'Buy Ocean Coins',
    select: 'Select the number of coins. The more coins, the cheaper',
    buyShort: 'Buy',
    support: 'Support via Telegram Stars and get Ocean Coins!',
    from: 'from',
  },
  collections: {
    title: 'Collections',
    total: 'Total',
    cards: 'cards',
    ready: 'You are ready on:',
    perHour: 'per hour',
    willBe: 'Will be available in:',
    available: 'Available only for:',
    buy: 'Buy for',
  },
} as const;
